.popup-story-component {
    background-color: #f6f6f5;
    margin: 0;
}

.component-title {
    color: #00529c;
    font:
        100 38px/38px "Open Sans",
        arial,
        sans-serif;
    margin-bottom: 25px;
}

.popup-story-component > section {
    width: 100%;
    max-width: 1200px;
    padding: 0;
    margin: 0 auto;
}

.popup-story-component--header {
    text-align: center;
    background-color: transparent;
}

.popup-story-component--list {
    text-align: center;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(232px, 1fr));
    justify-items: center;
    row-gap: 40px;
    padding: 50px 20px;
}

.popup-story-component .popup-story-component--list-item {
    min-width: 140px;
    margin: 0 5px; /* NOTE: May be unnecessary */
}

.popup-story-component .popup-story-component--list-item img {
    filter: grayscale(100%);
    height: 120px;
    width: 120px;
    margin-bottom: 15px;
}

.popup-story-component .popup-story-component--list-item img:hover {
    filter: none;
}

.popup-story-component .popup-story-component--list-item h3 {
    font-size: 16px;
    font-weight: 600;
}

.popup-story-component--header > p {
    color: #666666;
    line-height: 21px;
    font-size: 14px;
}

.popup-content {
    display: none;

    position: relative;
    flex-flow: column;
    padding: 8px;
    /* Used to center content instead of align- 
        See: https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container*/
    margin: auto;
    width: auto;
    max-width: 780px;
    background-color: #f6f6f5;
    box-shadow:
        0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px -1px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
}

.popup-content.show {
    display: flex;
}

.popup-content > .popup-header {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.popup-content figure {
    color: #333333;
    font-size: 16px;
    font-style: italic;
    width: 100%;
    color: #333333;
    font-weight: 400;
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    align-items: center;
    margin: 30px 0 30px 0;
}

.popup-content > .popup-body {
    display: block;
    background: #fff;
    padding: 30px 20px;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    border-radius: 2px;
}

.popup-content p,
.popup-content blockquote,
.popup-content figcaption,
.popup-story-component figcaption {
    line-height: 1.5;
}

.popup-content h3 {
    /* Reset margin and font-style set in global style */
    margin: 0;
    font-style: normal;
    color: #00529c;
    font-weight: 300;
}

.popup-content blockquote {
    color: unset;
    font-style: italic;
}

.popup-content button.close-button {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
    text-align: center;
    opacity: 0.65;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;

    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    display: block;
    outline: none;
    outline-color: initial;
    outline-style: none;
    outline-width: initial;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
}

.popup-story-component--header {
    padding-right: 0;
    grid-column: 1 / -1;
    justify-content: center;

    --header-partial-width-right-padding: 15%;
}

/* Two Columns */
@media screen and (min-width: 526px) {
    .popup-story-component--header-columns-2-span-1 {
        grid-column-end: span 2;
        padding-right: var(--header-partial-width-right-padding);
    }
}

/* Three Columns */
@media screen and (min-width: 758px) {
    .popup-story-component--header-columns-3-span-1 {
        grid-column-end: span 1;
        padding-right: var(--header-partial-width-right-padding);
    }

    .popup-story-component--header-columns-3-span-2 {
        grid-column-end: span 2;
        padding-right: var(--header-partial-width-right-padding);
    }
}

/* Four Columns */
@media screen and (min-width: 990px) {
    .popup-story-component--header-columns-4-span-1 {
        grid-column-end: span 1;
        padding-right: var(--header-partial-width-right-padding);
    }

    .popup-story-component--header-columns-4-span-2 {
        grid-column-end: span 2;
        padding-right: var(--header-partial-width-right-padding);
    }

    .popup-story-component--header-columns-4-span-3 {
        grid-column-end: span 3;
        padding-right: var(--header-partial-width-right-padding);
    }
}

/* Five Columns */
@media screen and (min-width: 1222px) {
    .popup-story-component--header-columns-5-span-1 {
        grid-column-end: span 1;
        padding-right: var(--header-partial-width-right-padding);
    }

    .popup-story-component--header-columns-5-span-2 {
        grid-column-end: span 2;
        padding-right: var(--header-partial-width-right-padding);
    }

    .popup-story-component--header-columns-5-span-3 {
        grid-column-end: span 3;
        padding-right: var(--header-partial-width-right-padding);
    }

    .popup-story-component--header-columns-5-span-4 {
        grid-column-end: span 4;
        padding-right: var(--header-partial-width-right-padding);
    }
}

@media screen and (min-width: 600px) {
    .popup-content {
        flex-flow: row nowrap;
    }

    .popup-content > .popup-header {
        width: 40%;
    }

    .popup-content > .popup-body {
        width: 60%;
        padding: 30px 20px;
    }

    .popup-content figure {
        /* Add extra right margin used to separate header from body for larger screens when in a row */
        margin-right: 8px;
    }
}

/* HACK: We override properties of overlay here now for convenience but these should be made in styles.css and made 
compatible with overlay usage from the sidebar functionality if we plan to reuse the same overlay. */
.overlay {
    position: fixed;
    overflow-y: scroll;
    display: flex;
}

html,
body {
    width: 100%;
    height: 100%;
}

.noscroll {
    overflow: hidden;
}

/* Detect desktop devices with hover capability and adjust for scroll bar width */
@media (any-hover: hover) {
    .noscroll {
        overflow: hidden;
        padding-right: 17px;
    }
    /* HACK: Apply overlay adjustment for other overlays */
    .preventScroll {
        padding-right: 8.5px;
    }
}
